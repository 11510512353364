/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { AmplitudeProvider } from '@amplitude/react-amplitude';

import Header from './header';
import Footer from './footer';
import './layout.scss';

const Layout = ({ nav, children }) => {
  const [amplitudeInstance, setAmplitudeInstance] = useState(null);

  useEffect(() => {
    if (typeof window === 'undefined') {
      setAmplitudeInstance({
        init: () => {},
        logEvent: () => {},
        setUserId: () => {},
        setUserProperties: () => {},
      });
    } else {
      const instance = require('amplitude-js').getInstance();
      instance.init(process.env.AMPLITUDE_KEY, null, {
        includeUtm: true,
        includeReferrer: true,
      });
      setAmplitudeInstance(instance);
    }
  }, []);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  if (!amplitudeInstance) {
    return null;
  }

  return (
    <AmplitudeProvider amplitudeInstance={amplitudeInstance}>
      <Header siteTitle={data.site.siteMetadata.title} nav={nav} />
      <main role="main">{children}</main>
      <Footer />
    </AmplitudeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
