import React from 'react';

import icon from '../images/icon.svg';

const Footer = () => {
  return (
    <footer className="pt-4 my-md-5 pt-md-5 border-top bg-white">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img className="mb-2" src={icon} alt="" width="24" height="24" />
            <small className="d-block mb-3 text-muted">lastmx.com</small>
          </div>
          <div className="col-md-3 offset-md-3">
            <h5>Help</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="mailto:hello@lastmx.com">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Follow Us</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="https://www.facebook.com">
                  Facebook
                </a>
              </li>
              <li>
                <a className="text-muted" href="https://twitter.com">
                  Twitter
                </a>
              </li>
              <li>
                <a className="text-muted" href="https://www.instagram.com">
                  Instagram
                </a>
              </li>
              <li>
                <a className="text-muted" href="https://www.producthunt.com">
                  Product Hunt
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
